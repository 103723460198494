import Domain from './_domain.js'
import { NotInitializedError } from '../exceptions.js'
import scopeMove from '@/actions/scopes/move.js'

let scopeCardsCaches = {}
// Needs to be tracked per project because we have at least one page that uses mixed
// data from multiple projects, e.g. projects#index.
const reindexRequests = {}
// const reindexRequest = null

export default class extends Domain {
  // Cache locking is useful for multi-step operations like create() and destroy() but shouldn't be needed for
  // single-step operations like update(). See cache()
  static _lockCache(projectId) {
    const cache = scopeCardsCaches[projectId] || {}
    // Be generous because:
    // * The operation might take a while to complete.
    // * The cache should be unlocked manually anyway once the operation has completed.
    cache.lockUntil = new Date().getTime() + 3000
    return cache
  }

  static _unlockCache(cache) {
    cache.lockUntil = null
  }

  static async create(data, listId, projectId, t, insertionIndex) {
    const cache = this._lockCache(projectId)

    const params = Object.assign({}, data, { idList: listId })
    const scopeCard = await $tpu.r.cards.create(params)
    await this.link(scopeCard.id, projectId, t, insertionIndex)

    this._unlockCache(cache)
    return scopeCard
  }

  static async destroy(scopeCardId, projectId, t) {
    const cache = this._lockCache(projectId)

    await $tpu.r.cards.destroy(scopeCardId)
    await this._removeFromProject(scopeCardId, projectId, t)

    this._unlockCache(cache)
  }

  static async link(scopeCardId, projectId, t, insertionIndex) {
    // Attach data to the Scope first as the authoritative data. This ensures that a child never becomes
    // orphaned. The worse that could happen is that it becomes out of order.
    await $tpu.r.cards.setData(scopeCardId, { [$tpu.keys.crdProjectId()]: projectId }, t)

    // Add to the ordering indices. No need to touch updatedAt since we rely on the above.
    return $tpu.r.projects.freshUpdate(projectId, t, (project) => {
      if (GLib.type.isNumber(insertionIndex)) {
        // On the source machine, there shouldn't be any risk of the card getting shown out of order because
        // this operation updates the cache right away. However on a destination machine, it is possible
        // that the new card will initially be shown at the end, and once the indices get propagated, then
        // new card will then get moved to the beginning.
        scopeMove.changeScopeIndex(scopeCardId, insertionIndex, project)
      } else {
        project.scopeCardIds.push(scopeCardId)
      }
    }, true)
  }

  static async _removeFromProject(scopeCardId, projectId, t) {
    // Pass `disableTouch=false` so it forces UI refresh.
    return $tpu.r.projects.freshUpdate(projectId, t, (project) => {
      project.scopeCardIds.remove(scopeCardId)
    }, false)
  }

  static async unlink(scopeCardId, projectId, t) {
    await $tpu.r.cards.setData(scopeCardId, { [$tpu.keys.crdProjectId()]: null }, t)

    return this._removeFromProject(scopeCardId, projectId, t)
  }

  // It should be okay to reindex asynchronously expecially since there is no avoiding reindexing in a multi-user
  // environment. Some cards will just go out of order when there is a race condition.
  //
  // The main purpose of this is to fix indices when they go out of order due to concurrent updates by
  // multiple users and non-atomic operations on peer machines.
  //
  // Under normal circumstances, this should not be needed on the source machine because caches are
  // generally updated in a sequantial manner due to 1-second card update, scope/task cache locking, and
  // card creation/deletion waiting.
  static async _reindex(scopeCardIds, projectId, t) {
    // Pass `disableTouch=false` so it forces UI refresh because a card might be deleted outside of the PU.
    // Also, even though precautions have been taken to avoid reindexing in controlled environments, there
    // will always unpredictable situations that we want to be able to recover from. Given that these situations
    // are very rare, the performance overhead of UI refresh will be negligible.
    return $tpu.r.projects.freshUpdate(projectId, t, (project) => {
      project.scopeCardIds = scopeCardIds
    }, false)
  }

  static _orderScopeCards(scopeCards, project, t) {
    const projectId = project.id
    let reindex = false
    const array = []
    const scopeCardIds = project.scopeCardIds || []
    scopeCardIds.forEach((cardId) => {
      const card = scopeCards[cardId]
      if (card && card.shapeUp.projectId == projectId) {
        array.push(card)
        delete scopeCards[cardId]
      } else {
        reindex = true
      }
    })

    // Attach unindexed scopes to the end.
    Object.values(scopeCards).forEach((card) => {
      reindex = true
      array.push(card)
    })

    const reindexRequest = reindexRequests[projectId] = reindexRequests[projectId] || {}
    if (reindex) {
      if (this.requestReindex(reindexRequest, scopeCardIds.length, array.length)) {
        console.warn(`Reindexing scope cards...`)
        const latestIds = array.map(card => card.id)
        this._reindex(latestIds, projectId, t)
      }
    } else {
      this.cancelReindex(reindexRequest)
    }

    return [reindex, array]
  }

  static _sortCards(cards, project, t) {
    const scopeCards = {}
    const groupedTaskCards = {}

    cards.forEach((card) => {
      const scopeCardId = card.shapeUp.scopeCardId
      if (scopeCardId) {
        groupedTaskCards[scopeCardId] = groupedTaskCards[scopeCardId] || {}
        groupedTaskCards[scopeCardId][card.id] = card
      } else if (card.shapeUp.projectId == project.id) {
        scopeCards[card.id] = card
      }
    })

    return this._orderScopeCards(scopeCards, project, t, groupedTaskCards).concat([groupedTaskCards])
  }

  static cache(project, t, loadTasks) {
    const frozenCache = $tpu.r.cards.cache()
    const allCards = Object.values(frozenCache)
    // if (allCards.length <= 0) { // For predictability
    //   console.warn("Card cache not initialized")
    //   return null
    // }

    const currentTimestamp = new Date().getTime()

    // Return right away so we don't try to use data that is being updated, which in general shouldn't be a problem
    // apart from unnecessary reindexing, but this is just an added safety.
    const lockedCache = scopeCardsCaches[project.id]
    if (lockedCache && lockedCache.lockUntil > currentTimestamp) {
      // console.debug("Returning locked cache")
      return lockedCache
    }

    const [indexInconsistent, scopeCards, groupedTaskCards] = this._sortCards(allCards, project, t)
    // Avoid changing lastUpdatedAt which might prevent UI refresh later when the index eventually
    // becomes consistent.
    if (indexInconsistent) {
      if (lockedCache) {
        return lockedCache
      }

      // `lockedCache` will be null if the page is still initializing.
      throw new NotInitializedError()
    }

    // This will be false if at least one card has incomplete accessory.
    let accessoryComplete = true
    let lastUpdatedAt = project.updatedAt

    const array = []
    scopeCards.forEach((card) => {
      card.shapeUp.list = $tpu.r.lists.find(card.idList)
      card.shapeUp.hillPointX = card.shapeUp.hillPointX || 0

      if (loadTasks) {
        // Assign vars to a temporary property so it doesn't get accidentally saved to the card
        card.transient = card.transient || {}
        const taskCache = $tpu.r.tasks.$cache(card, groupedTaskCards[card.id] || {}, t)
        card.transient.taskCards = taskCache.data

        if (taskCache.lastRefreshedAt > lastUpdatedAt) {
          lastUpdatedAt = taskCache.lastRefreshedAt
        }
      }

      // TODO: It seems that we need to check the taskCache accessory too because relying on
      // taskCache's timestamp is just not reliable.
      if (!card.shapeUp.list) {
        accessoryComplete = false
      }
      array.push(card)

      if (card.shapeUp.updatedAt > lastUpdatedAt) {
        lastUpdatedAt = card.shapeUp.updatedAt
      }
    })

    const existingCache = scopeCardsCaches[project.id]
    let latestCache = existingCache
    if (!existingCache || lastUpdatedAt > existingCache._lastUpdatedAt) {
      latestCache = {
        data: array,
        lastRefreshedAt: currentTimestamp,
        _lastUpdatedAt: accessoryComplete ? lastUpdatedAt : 0
      }
      scopeCardsCaches[project.id] = latestCache
    }
    return latestCache
  }

  static clearCache() {
    scopeCardsCaches = {}
  }
}
